import { Element } from "react-scroll"

export default function About_me() {
    return (
        <Element id="about-me" className="section" name="about-me">
            <section id="about-intro">
                
            </section>
        </Element>
    )
}