import Navbar from "./Navbar";
import Header from "./components/Header";
import Home from "./Home";
import About_me from "./About_me";
import Footer from "./Footer";

function Portfolio() {
    return (
        <>
            <Navbar />
            <Home />
            <About_me />
            <Footer />
        </>
    )
}

export default Portfolio;
