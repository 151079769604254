

export default function Footer() {
    return (
        <div id="footer" className="section">
            <div id="footer-content">
                
            </div>
        </div>
    )
}